var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-dialog',{attrs:{"max-width":"1100px","persistent":"","transition":"dialog-top-transition"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))]),_c('v-spacer'),_c('v-radio-group',{attrs:{"row":"","mandatory":"","dense":""},model:{value:(_vm.besoin),callback:function ($$v) {_vm.besoin=$$v},expression:"besoin"}},[_c('v-radio',{attrs:{"label":"Besoins","value":true}}),_c('v-radio',{attrs:{"label":"Produits","value":false}})],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"dense":""}},[(_vm.besoin)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('v-autocomplete',{attrs:{"item-value":'id',"items":_vm.products_list,"filter":_vm.customFilter,"label":"Produit","rules":[function (v) { return !!v || 'Produit obligatoire'; }],"dense":"","loading":_vm.loading},on:{"change":_vm.product_change},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.no_da + "-" + (item.code ? item.code + "-" : "") + item.label)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.no_da + "-" + (item.code ? item.code + "-" : "") + item.label + "-" + item.qte_reste + " " + item.unit_name)+" ")]}}],null,false,289540165),model:{value:(_vm.editedItem.dem_det_id),callback:function ($$v) {_vm.$set(_vm.editedItem, "dem_det_id", $$v)},expression:"editedItem.dem_det_id"}})],1):_vm._e(),(!_vm.besoin)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"8"}},[_c('cursorselect',{key:_vm.cs,attrs:{"Qsearch":_vm.products_cursor,"Qresp":'products_cursor',"value":_vm.editedItem.produit_id,"text_fields":['code', 'label'],"variableadd":_vm.variableadd,"label":'Produit'},on:{"change":_vm.product_change}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-text-field',{ref:"qte",staticClass:"inputPrice",attrs:{"autocomplete":"off","type":"number","dense":"","readonly":_vm.readonly,"label":_vm.editedItem.unit_name ? _vm.editedItem.unit_name : 'Quantité',"rules":[
                    function (v) { return !!v ||
                      (_vm.editedItem.unit_name
                        ? _vm.editedItem.unit_name
                        : 'Quantité') + ' obligatoire'; },
                    function (v) { return !v || v > 0 || 'Quantité incorrecte'; },
                    function (v) { return !v ||
                      !_vm.besoin ||
                      parseFloat(v) <= parseFloat(_vm.max_qte) ||
                      'Quantité Max ' +
                        _vm.max_qte +
                        (_vm.editedItem.unit ? ' ' + _vm.editedItem.unit : ''); } ],"hide-spin-buttons":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.save.apply(null, arguments)}},model:{value:(_vm.editedItem.qte),callback:function ($$v) {_vm.$set(_vm.editedItem, "qte", $$v)},expression:"editedItem.qte"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"1","md":"10"}},[_c('v-text-field',{ref:"comment",attrs:{"autocomplete":"off","dense":"","label":"Commentaire","readonly":_vm.readonly},model:{value:(_vm.editedItem.comment),callback:function ($$v) {_vm.$set(_vm.editedItem, "comment", $$v)},expression:"editedItem.comment"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-container',{attrs:{"fill-height":""}},[(_vm.progress)?_c('v-layout',{attrs:{"row":"","justify-center":"","align-center":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":30,"width":7,"color":"purple"}})],1):_vm._e()],1),_c('v-spacer'),(!_vm.readonly)?_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Enregistrer ")]):_vm._e(),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v(" Fermer ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":_vm.snackbar_timeout,"top":"","color":_vm.snackbar_color},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbar_text)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }